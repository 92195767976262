























































































































































































































import { differenceInDays, lightFormat, parseISO } from "date-fns";

import { BezugsvorschlagModel } from "@/models/BezugsvorschlagDataModel";
import { MengenDisplay } from "@/models/util/BuchungsMonitorDisplayModel";
import Vue from "vue";
import { catVue } from "@/util/logging";
import { toNum } from "@/util/converters";

export default Vue.extend({
     //
     name: "BuchungsMonitorDetail",

     props: {
       menge: Object,
       von: {
         type: String,
         required: true,
       },
       bis: {
         type: String,
         required: true,
       },
     },

     data() {
       return {
         bzvValue: new Array<number>(),
         bzvKommentar: `Mengenkontrolle am ${lightFormat(new Date(), "d.M.yy")}`,
         showBZV: false,
         editBZV: true,
         savingBZV: false,
         rules: [
           (val: string) => {
             if (undefined === val || val === "") return true;
             const parsed = parseInt(val, 10);
             if (isNaN(parsed)) return false;
             return parsed > 0;
           },
         ],
       };
     },

     methods: {
       async speichern() {
         this.editBZV = false;
         this.savingBZV = true;

         let callCt = 0;

         const menge = this.menge as MengenDisplay;
         menge.filialen.forEach((fil) => {
           const vorschlag = toNum(this.bzvValue[fil.nr]);
           if (vorschlag !== undefined && vorschlag > 0) {
             callCt++;
             BezugsvorschlagModel.create(
               menge.ztgObj,
               fil,
               -1,
               -1,
               vorschlag,
               this.bzvKommentar,
               false,
               false
             )
               .then((result) => {
                 catVue.info(`Filiale ${fil.kurz} => ${result}`);
                 if (--callCt == 0) this.savingBZV = false;
                 menge.bzvValues[fil.nr] = vorschlag;
               })
               .catch((error) => {
                 catVue.info(`Error: Filiale ${fil.kurz} => ${error}`);
                 if (--callCt == 0) this.savingBZV = false;
               });

             menge.bzvKommentar = this.bzvKommentar;
           }
         });

         this.savingBZV = false;
       },
       sensibleEVT(evt: Date | null) {
         if (null == evt) return true;

         let von = parseISO(this.von);
         if (Math.abs(differenceInDays(von, evt)) > 5) {
           return false;
         }

         return true;
       },
       sensibleDispo(dispo: Date | null) {
         if (null == dispo) return true;

         let von = parseISO(this.von);
         if (differenceInDays(von, dispo) > 50) {
           return false;
         }

         return true;
       },
     },

     mounted() {
       catVue.info(
         `${this.$options.name}:: MOUNTED ... ${this.menge.bzvKommentar}`
       );
       // eslint-disable-next-line
       if (this.menge.bzvValues.length) {
         catVue.info("putting in Values from menge …");
         for (let index = 0; index < this.menge.bzvValues.length; index++) {
           this.bzvValue[index] = this.menge.bzvValues[index];
         }
         this.bzvKommentar = this.menge.bzvKommentar;
         this.editBZV = false;
         this.showBZV = true;
       }
     },
   });
